import { graphql } from "gatsby";
import React from "react";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { MainHeading } from "../components/MainHeading";
import { Page } from "../components/Page";

SwiperCore.use([Autoplay, EffectFade]);

type IndexPageProps = {
  data: GatsbyTypes.HomeSliderQuery;
};

export default function IndexPage({ data }: IndexPageProps) {
  return (
    <Page>
      <div className="flex flex-col-reverse lg:flex-row lg:items-center gap-8">
        <div className="lg:w-1/2">
          <MainHeading>Herzlich willkommen</MainHeading>
          <div className="prose">
            <p>
              auf der Homepage der Ehemaligen der United Brass Big Band des
              Gymnasiums an der Wolfskuhle (und zuvor der
              Human(n)-Brass-Big-Band des Carl-Humann-Gymnasiums) in Essen.
            </p>
            <p>
              Diese Seite soll nunmehr als Ehemaligen-Seite für die Jahre
              1994-2019, also die ersten 25 Jahre die Bandgeschichte in Bildern,
              Zeitungsausschnitten und Informationstexten möglichst gut
              dokumentieren, aber auch Informationen über anstehende Treffen der
              Ehemaligen oder Konzerte geben.
            </p>
            <p>
              Natürlich sollen hier auch die"Wolfpack-Drumline" und die mit der
              Theatergruppe der Wolfskuhle auf die Bühne gebrachten Musicals
              angemessen dargestellt sein.
            </p>
            <p>
              Wir bemühen uns, die Seite stets aktuell und informativ zu halten
              und hoffen, dass Ihr Euch gut zurechtfindet.
            </p>
            <p>
              Viel Spaß wünschen
              <br />
              Robert und Manuel
            </p>
          </div>
        </div>
        <div className="lg:w-1/2">
          <Swiper
            autoplay={{ delay: 4000 }}
            effect="fade"
            speed={3000}
            fadeEffect={{ crossFade: true }}
          >
            {data.allHomeSliderJson.nodes.map(({ image }) => (
              <SwiperSlide key={image?.id}>
                <img src={image?.publicURL} alt="" role="presentation" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Page>
  );
}

export const query = graphql`
  query HomeSlider {
    allHomeSliderJson {
      nodes {
        image {
          id
          publicURL
        }
      }
    }
  }
`;
